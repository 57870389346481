/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import SelectField from 'components/Form/SelectNew';
import TextInput from 'components/Form/TextInputNew';
import TextArea from 'components/Form/TextArea';

// hooks
import useFetchList from 'hooks/useList';
import { useMutate, useFetchUser, useFetchDetail } from 'hooks';
import resourceQuery from 'models/resource/query';
import skillsQuery from 'models/skills/query';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import Helper from 'utils/helpers';
import './style.less';
import '../skills-style.less';

import { IListKnowledge, IUpdateTechnical, ICreateTechnical } from 'models/skills/interface';
import { ITechnicalCategory, ITechnicalSkill, ILevelItem } from 'models/resource/interface';
import FormSchema from './validate';

type FormValues = InferType<typeof FormSchema>;

const TechnicalForm: React.FC<{
  initialValue: IListKnowledge | Record<string, unknown>;
  resetInitialValue: () => void;
  refreshDataList: () => void;
}> = ({ initialValue, resetInitialValue, refreshDataList }) => {
  // state
  const [initialForm, setInitialForm] = useState({});
  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });
  const [technicalList, setTechnicalList] = useState<any>([
    {
      _id: 'other',
      nameJa: 'その他',
      nameEn: 'Other',
    },
  ]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialForm,
  });
  const { t } = useTranslation();

  const { data } = useFetchUser({ enabled: true });
  // get all category list - Level list
  const { list: listCategory, isFetching: fetchingCategoryData } = useFetchList<ITechnicalCategory>(
    { ...resourceQuery.technicalCategory },
  );

  const { list: listLevel, isFetching: fetchingLevelData } = useFetchList<ILevelItem>(
    resourceQuery.level,
  );

  const { mutateAsync: create, isLoading: loadingCreate } = useMutate<ICreateTechnical>(
    skillsQuery.createTechnical,
  );
  const { mutateAsync: update, isLoading: loadingUpdate } = useMutate<IUpdateTechnical>(
    skillsQuery.updateTechnical,
  );

  const categoryValue = useWatch({ name: 'category', control: control });
  const skillNameValue = useWatch({ name: 'skillName', control: control });
  const skillText = useWatch({ name: 'otherSkillName', control: control });

  // get Skill list from category
  const { list: listSkill, refetch: refetchListSkill } = useFetchList<ITechnicalSkill>({
    ...resourceQuery.technicalSkill,
    enabled: !!categoryValue,
    apiUrl: `/v1/experience/technical/skill/${categoryValue}`,
  });

  useEffect(() => {
    setInitialForm({
      ...initialValue,
      category: _get(initialValue, 'skillCategory._id'),
      skillName: _get(initialValue, 'skillName._id'),
      otherSkillName: _get(initialValue, 'otherSkillName'),
      level: _get(initialValue, 'level._id'),
    });
    setValue('category', _get(initialValue, 'skillCategory._id', ''));
    setValue('level', _get(initialValue, 'level._id', ''));
    setValue('skillName', _get(initialValue, 'skillName._id', ''));
    setValue('otherSkillName', _get(initialValue, 'otherSkillName', '') as string);

    if (!_isEmpty(_get(initialValue, '_id'))) {
      if (!_isEmpty(_get(initialValue, 'otherSkillName'))) {
        setInitialForm({
          ...initialValue,
          category: _get(initialValue, 'skillCategory._id', ''),
          skillName: 'other',
          otherSkillName: _get(initialValue, 'otherSkillName', ''),
          level: _get(initialValue, 'level._id'),
        });
        setValue('skillName', 'other');
        setValue('otherSkillName', _get(initialValue, 'otherSkillName') as string);
      } else {
        setInitialForm({
          ...initialValue,
          category: _get(initialValue, 'skillCategory._id'),
          skillName: _get(initialValue, 'skillName._id'),
          otherSkillName: _get(initialValue, _get(initialValue, 'skillName.name', '')),
          level: _get(initialValue, 'level._id'),
        });
        setValue('skillName', _get(initialValue, 'skillName._id', '') as string);
        setValue('otherSkillName', _get(initialValue, 'skillName.name', '') as string);
      }
    }
    clearErrors();
  }, [initialValue]);

  useEffect(() => {
    reset(initialForm);
  }, [initialForm]);

  useEffect(() => {
    if (!_isEmpty(categoryValue)) {
      refetchListSkill();
    }
  }, [categoryValue]);

  useEffect(() => {
    if (skillNameValue !== 'other') {
      clearErrors('skillName');
      setValue('otherSkillName', skillNameValue);
    }
  }, [skillNameValue]);

  const clearUpdateForm = () => {
    setInitialForm({});
    reset({});
    resetInitialValue();
  };

  const handleFormSubmit = (values: FormValues) => {
    let valuesSubmit = {
      detail: values.detail,
      experienceYears: Number(values.experienceYears),
      level: values.level,
      skillCategory: values.category,
      user: data?._id || '',
    };
    if (skillNameValue === 'other') {
      valuesSubmit = Object.assign(valuesSubmit, {
        otherSkillName: values.otherSkillName,
      });
    } else {
      valuesSubmit = Object.assign(valuesSubmit, {
        skillName: values.skillName,
      });
    }
    if (_isEmpty(_get(initialForm, '_id'))) {
      create(
        { ...valuesSubmit },
        {
          onSuccess: () => {
            Helper.toasts('', t('global:messageSuccess'), 'success');
            reset();
            refreshDataList();
          },
        },
      );
    } else {
      update(
        {
          ...valuesSubmit,
          categoryTechnical: _get(initialForm, '_id', ''),
        },
        {
          onSuccess: () => {
            Helper.toasts('', t('skills:updateSuccess'), 'success');
            clearUpdateForm();
            refreshDataList();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (listSkill.length > 0) {
      const skillMap: Record<string, any> = {};
      let lastCategory = '';
      listSkill.map((skill) => {
        const categoryName = _get(skill, 'skillTechnical.name');
        lastCategory = categoryName || '';
        if (!skillMap[categoryName || '']) skillMap[categoryName || ''] = [];
        skillMap[categoryName || ''].push({ value: skill._id, label: skill.nameJa });
      });
      if (Object.keys(skillMap).length > 1) {
        skillMap[lastCategory].push({ label: 'その他', value: 'other' });
        setTechnicalList(
          Object.keys(skillMap).map((key) => ({ label: key, options: skillMap[key] })),
        );
      } else {
        setTechnicalList([
          ...listSkill,
          {
            _id: 'other',
            nameJa: 'その他',
            nameEn: 'Other',
          },
        ]);
      }
    }
  }, [listSkill]);

  return (
    <form
      className="skills-form-container technical-form-container"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Spin spinning={fetchingCategoryData || fetchingLevelData}>
        <div className="roles-form-block">
          <div className="row-block">
            <SelectField
              name="category"
              label={t('skills:skillCategoryField')}
              control={control}
              formLayout="vertical"
              required
              placeholder={t('placeholder:fieldSelect')}
              data={listCategory}
              colLabel={12}
              colWrapper={12}
              onSelect={(value) => {
                if (!_isEqual(value, categoryValue)) {
                  setValue('level', '');
                  clearErrors('level');
                  setValue('skillName', '');
                  clearErrors('skillName');
                  clearErrors('category');
                }
              }}
              onClear={() => {
                setValue('category', '', { shouldValidate: true });
                setValue('level', '');
                clearErrors('level');
                setValue('skillName', '');
                clearErrors('skillName');
              }}
            />
          </div>
          <div
            className={`row-block ${
              categoryValue && skillNameValue && skillNameValue === 'other' ? 'show-skill-text' : ''
            }`}
          >
            <SelectField
              name="skillName"
              label={t('skills:skillName')}
              control={control}
              formLayout="vertical"
              required
              placeholder={t('placeholder:fieldSelect')}
              data={[...technicalList]}
              colLabel={12}
              colWrapper={12}
              onSelect={(value) => {
                if (!_isEqual(value, skillNameValue)) {
                  setValue('level', '');
                  clearErrors('skillName');
                  clearErrors('otherSkillName');
                  setValue('otherSkillName', '');
                }
              }}
              onClear={() => {
                setValue('skillName', '', { shouldValidate: true });
                setValue('otherSkillName', '', { shouldValidate: true });
                setValue('level', '');
                clearErrors('level');
              }}
              disabled={!categoryValue}
            />
            {categoryValue && skillNameValue && skillNameValue === 'other' && (
              <TextInput
                name="otherSkillName"
                control={control}
                required
                placeholder={t('placeholder:fieldDetail')}
                formLayout="vertical"
                colLabel={12}
                colWrapper={12}
                disabled={!categoryValue || !skillNameValue}
                maxLength={100}
                isShowMaxLength={false}
              />
            )}
          </div>
          <div className="row-block">
            <SelectField
              name="level"
              label={t('skills:skillLevelTechnical')}
              formLayout="vertical"
              control={control}
              required
              placeholder={t('placeholder:fieldSelect')}
              data={listLevel}
              onClear={() => setValue('level', '', { shouldValidate: true })}
              disabled={!categoryValue || !skillNameValue || !skillText}
              colLabel={12}
              colWrapper={12}
              helperText={_get(dataHelpText, 'value.formHelpTextTechnicalPage.level')}
            />
          </div>
          <div className="row-block number-year">
            <TextInput
              name="experienceYears"
              control={control}
              label={t('skills:experienceYearsField')}
              required
              placeholder={t('placeholder:fieldExperienceYears')}
              suffix="年"
              colLabel={12}
              colWrapper={12}
              formLayout="vertical"
            />
          </div>
          <div className="row-block full-row">
            <TextArea
              name="detail"
              control={control}
              label={t('skills:detailField')}
              placeholder={t('placeholder:fieldDetail')}
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={false}
              colLabel={12}
              colWrapper={24}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextTechnicalPage.detail')}
            />
          </div>

          <div className="submit-btn-wrapper">
            {!_isEmpty(_get(initialForm, '_id')) && (
              <Button
                htmlType="reset"
                className="btn-cancel"
                type="primary"
                onClick={() => clearUpdateForm()}
              >
                {t('button:cancelButton')}
              </Button>
            )}
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingCreate || loadingUpdate}
              disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default TechnicalForm;
