/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import SelectField from 'components/Form/SelectNew';
import TextInput from 'components/Form/TextInputNew';
// hooks
import useFetchList from 'hooks/useList';
import { useMutate } from 'hooks';
import resourceQuery from 'models/resource/query';
import workEnvironmentQuery from 'models/work-environment/query';
import Helper from 'utils/helpers';
import './style.less';
import { ITechnicalCategory, ITechnicalSkill } from 'models/resource/interface';
import { IUpdateTechnologies, ICreateTechnologist } from 'models/work-environment/interface';
import RadioGroup from 'components/Form/RadioGroup';
import { RADIO_CHOOSE } from 'utils/constants';
import FormSchema from './validate';
type FormValues = InferType<typeof FormSchema>;

const TechnologiesForm: React.FC<{
  initialValue: IUpdateTechnologies | Record<string, unknown>;
  resetInitialValue: () => void;
  refreshDataList: () => void;
}> = ({ initialValue, resetInitialValue, refreshDataList }) => {
  // state
  const [initialForm, setInitialForm] = useState({});
  const [initialSkillList, setInitialSkillList] = useState<any>([
    {
      _id: 'other',
      nameJa: 'その他',
      nameEn: 'Other',
    },
  ]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialForm,
  });
  const { t } = useTranslation();

  // get all category list - Level list
  const { list: listCategory, isFetching: fetchingCategoryData } = useFetchList<ITechnicalCategory>(
    { ...resourceQuery.technicalCategory },
  );

  const { mutateAsync: create, isLoading: loadingCreate } = useMutate<ICreateTechnologist>(
    workEnvironmentQuery.createTechnologies,
  );

  const { mutateAsync: update, isLoading: loadingUpdate } = useMutate<{
    technology: string;
  }>(workEnvironmentQuery.updateTechnologies);

  const categoryValue = useWatch({ name: 'category', control: control });
  const skillNameValue = useWatch({ name: 'skillName', control: control });

  // get Skill list from category
  const { list: listSkill, refetch: refetchListSkill } = useFetchList<ITechnicalSkill>({
    ...resourceQuery.technicalSkill,
    enabled: !!categoryValue,
    apiUrl: `/v1/experience/technical/skill/${categoryValue}`,
  });

  useEffect(() => {
    setInitialForm({
      ...initialValue,
      category: _get(initialValue, 'usedTechnologyCategory._id'),
      skillName: _get(initialValue, 'usedTechnologyName._id'),
      otherSkillName: _get(initialValue, 'otherUsedTechnologyName'),
    });
    setValue('category', _get(initialValue, 'usedTechnologyCategory._id', '') as string);
    setValue('skillName', _get(initialValue, 'usedTechnologyName._id', ''));
    let otherSkillName = _get(initialValue, 'otherUsedTechnologyName', '');
    setValue('otherSkillName', typeof otherSkillName === 'string' ? otherSkillName : '');

    if (!_isEmpty(_get(initialValue, '_id'))) {
      otherSkillName = _get(initialValue, 'otherUsedTechnologyName', '');
      if (!_isEmpty(_get(initialValue, 'otherUsedTechnologyName'))) {
        setInitialForm({
          ...initialValue,
          category: _get(initialValue, 'usedTechnologyCategory._id'),
          skillName: 'other',
          otherSkillName: _get(initialValue, 'otherUsedTechnologyName'),
        });
        setValue('skillName', 'other');
        setValue('otherSkillName', typeof otherSkillName === 'string' ? otherSkillName : '');
      } else {
        setInitialForm({
          ...initialValue,
          category: _get(initialValue, 'usedTechnologyCategory._id'),
          skillName: _get(initialValue, 'usedTechnologyName._id'),
          otherSkillName: _get(initialValue, _get(initialValue, 'usedTechnologyName.name', '')),
        });
        setValue('skillName', _get(initialValue, 'usedTechnologyName._id', ''));
        setValue('otherSkillName', _get(initialValue, 'usedTechnologyName.name', ''));
      }
    }

    setInitialSkillList((current) => [
      {
        _id: _get(initialValue, 'usedTechnologyName._id'),
        nameJa: _get(initialValue, 'usedTechnologyName.name'),
        nameEn: _get(initialValue, 'usedTechnologyName.name'),
      },
      ...current,
    ]);
    clearErrors();
  }, [initialValue]);

  useEffect(() => {
    reset(initialForm);
  }, [initialForm]);

  useEffect(() => {
    if (!_isEmpty(categoryValue)) {
      refetchListSkill();
    }
  }, [categoryValue]);

  useEffect(() => {
    if (listSkill.length > 0) {
      const skillMap: Record<string, any> = {};
      let lastCategory = '';
      listSkill.map((skill) => {
        const categoryName = _get(skill, 'skillTechnical.name');
        lastCategory = categoryName || '';
        if (!skillMap[categoryName || '']) skillMap[categoryName || ''] = [];
        skillMap[categoryName || ''].push({ value: skill._id, label: skill.nameJa });
      });
      if (Object.keys(skillMap).length > 1) {
        skillMap[lastCategory].push({ label: 'その他', value: 'other' });
        setInitialSkillList(
          Object.keys(skillMap).map((key) => ({ label: key, options: skillMap[key] })),
        );
      } else {
        setInitialSkillList([
          ...listSkill,
          {
            _id: 'other',
            nameJa: 'その他',
            nameEn: 'Other',
          },
        ]);
      }
    }
  }, [listSkill]);

  useEffect(() => {
    if (skillNameValue !== 'other') {
      clearErrors('skillName');
      setValue('otherSkillName', skillNameValue);
    }
  }, [skillNameValue]);

  const clearUpdateForm = () => {
    setInitialForm({});
    reset({});
    resetInitialValue();
  };

  const handleFormSubmit = (values: FormValues) => {
    let valuesSubmit = {
      usedTechnologyCategory: values.category,
      choose: values.choose,
      otherUsedTechnologyName: '',
      usedTechnologyName: '',
    };
    if (skillNameValue === 'other') {
      valuesSubmit = Object.assign(valuesSubmit, {
        otherUsedTechnologyName: values.otherSkillName,
      });
    } else {
      valuesSubmit = Object.assign(valuesSubmit, {
        usedTechnologyName: values.skillName,
      });
    }
    if (_isEmpty(_get(initialForm, '_id'))) {
      create(
        { ...valuesSubmit },
        {
          onSuccess: () => {
            Helper.toasts('', t('global:messageSuccess'), 'success');
            reset();
            refreshDataList();
          },
        },
      );
    } else {
      update(
        {
          ...valuesSubmit,
          technology: _get(initialForm, '_id', ''),
        },
        {
          onSuccess: () => {
            Helper.toasts('', t('skills:updateSuccess'), 'success');
            clearUpdateForm();
            refreshDataList();
          },
        },
      );
    }
  };

  return (
    <form
      className="skills-form-container technical-form-container"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Spin spinning={fetchingCategoryData}>
        <div className="roles-form-block">
          <div className="row-block">
            <SelectField
              name="category"
              label={t('skills:skillCategoryField')}
              control={control}
              formLayout="vertical"
              required
              placeholder={t('placeholder:fieldSelect')}
              data={listCategory}
              colLabel={12}
              colWrapper={12}
              onSelect={(value) => {
                if (!_isEqual(value, categoryValue)) {
                  setValue('skillName', '');
                  clearErrors('skillName');
                  clearErrors('category');
                }
              }}
              onClear={() => {
                setValue('category', '', { shouldValidate: true });
                setValue('skillName', '');
                clearErrors('skillName');
              }}
            />
          </div>
          <div
            className={`row-block ${
              categoryValue && skillNameValue && skillNameValue === 'other' ? 'show-skill-text' : ''
            }`}
          >
            <SelectField
              name="skillName"
              label={t('skills:skillName')}
              control={control}
              formLayout="vertical"
              required
              placeholder={t('placeholder:fieldSelect')}
              data={initialSkillList}
              colLabel={12}
              colWrapper={12}
              onSelect={() => {
                clearErrors('skillName');
                clearErrors('otherSkillName');
                setValue('otherSkillName', '');
              }}
              onClear={() => {
                setValue('skillName', '', { shouldValidate: true });
                setValue('otherSkillName', '', { shouldValidate: true });
              }}
              disabled={!categoryValue}
            />
            {categoryValue && skillNameValue && skillNameValue === 'other' && (
              <TextInput
                name="otherSkillName"
                control={control}
                required
                placeholder={t('placeholder:fieldDetail')}
                formLayout="vertical"
                colLabel={12}
                colWrapper={12}
                disabled={!categoryValue || !skillNameValue}
                maxLength={100}
                isShowMaxLength={false}
              />
            )}
          </div>
          <div className="row-block">
            <RadioGroup
              name="choose"
              label={'選好'}
              formLayout="vertical"
              colLabel={12}
              onChange={(e) => setValue('choose', e.target.value)}
              value={useWatch({ name: 'choose', control: control })}
              required
              control={control}
              options={RADIO_CHOOSE}
            />
          </div>

          <div className="submit-btn-wrapper">
            {!_isEmpty(_get(initialForm, '_id')) && (
              <Button
                htmlType="reset"
                className="btn-cancel"
                type="primary"
                onClick={() => clearUpdateForm()}
              >
                {t('button:cancelButton')}
              </Button>
            )}
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingCreate || loadingUpdate}
              disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default TechnologiesForm;
