/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import SelectField from 'components/Form/SelectNew';
import TextInput from 'components/Form/TextInputNew';
import TextArea from 'components/Form/TextArea';

// hooks
import useFetchList from 'hooks/useList';
import { useMutate, useFetchUser, useFetchDetail } from 'hooks';
import resourceQuery from 'models/resource/query';
import skillsQuery from 'models/skills/query';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import Helper from 'utils/helpers';
import './style.less';
import '../skills-style.less';

import { IListKnowledge, IUpdateLanguage, ICreateLanguage } from 'models/skills/interface';
import { ILanguageTypeItem, ILevelItem } from 'models/resource/interface';
import FormSchema from './validate';

type FormValues = InferType<typeof FormSchema>;

const KnowledgeForm: React.FC<{
  initialValue: IListKnowledge | Record<string, unknown>;
  resetInitialValue: () => void;
  refreshDataList: () => void;
}> = ({ initialValue, resetInitialValue, refreshDataList }) => {
  // state
  const [initialForm, setInitialForm] = useState({});
  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialForm,
  });
  const { t } = useTranslation();

  const { data } = useFetchUser({ enabled: true });
  // get all Language types list - Level list
  const { list: listLanguageTypes, isFetching: fetchingIndustriesData } =
    useFetchList<ILanguageTypeItem>({ ...resourceQuery.languageTypes });

  const { list: listLevel, isFetching: fetchingLevelData } = useFetchList<ILevelItem>(
    resourceQuery.level,
  );

  const { mutateAsync: create, isLoading: loadingCreate } = useMutate<ICreateLanguage>(
    skillsQuery.createLanguage,
  );
  const { mutateAsync: update, isLoading: loadingUpdate } = useMutate<IUpdateLanguage>(
    skillsQuery.updateLanguage,
  );

  const typesValue = useWatch({ name: 'type', control: control });

  useEffect(() => {
    setInitialForm({
      ...initialValue,
      type: _get(initialValue, 'type._id'),
      level: _get(initialValue, 'level._id'),
    });
    setValue('type', _get(initialValue, 'type._id', ''));
    setValue('level', _get(initialValue, 'level._id', ''));
    clearErrors();
  }, [initialValue]);

  useEffect(() => {
    reset(initialForm);
  }, [initialForm]);

  const clearUpdateForm = () => {
    setInitialForm({});
    reset({});
    resetInitialValue();
  };

  const handleRolesSubmit = (values: FormValues) => {
    if (_isEmpty(_get(initialForm, '_id'))) {
      create(
        {
          ...values,
          experienceYears: Number(values.experienceYears),
          user: data?._id || '',
          type: values.type,
        },
        {
          onSuccess: (data) => {
            Helper.toasts('', t('global:messageSuccess'), 'success');
            reset();
            refreshDataList();
          },
        },
      );
    } else {
      update(
        {
          languageExperience: _get(initialForm, '_id', ''),
          ...values,
          experienceYears: Number(values.experienceYears),
          // user: data?._id || '',
        },
        {
          onSuccess: (data) => {
            Helper.toasts('', t('skills:updateSuccess'), 'success');
            clearUpdateForm();
            refreshDataList();
          },
        },
      );
    }
  };

  return (
    <form
      className="skills-form-container language-form-container"
      onSubmit={handleSubmit(handleRolesSubmit)}
    >
      <Spin spinning={fetchingIndustriesData || fetchingLevelData}>
        <div className="roles-form-block">
          <div className="row-block">
            <SelectField
              name="type"
              label={t('skills:languageField')}
              control={control}
              formLayout="vertical"
              required
              placeholder={t('placeholder:fieldSelect')}
              data={listLanguageTypes}
              colLabel={12}
              colWrapper={12}
              onSelect={(value) => {
                if (!_isEqual(value, typesValue)) {
                  setValue('level', '');
                  clearErrors('level');
                  clearErrors('type');
                }
              }}
              onClear={() => {
                setValue('type', '', { shouldValidate: true });
                setValue('level', '');
                clearErrors('level');
              }}
            />
          </div>
          <div className="row-block">
            <SelectField
              name="level"
              label={t('skills:levelField')}
              formLayout="vertical"
              control={control}
              required
              placeholder={t('placeholder:fieldSelect')}
              data={listLevel}
              onClear={() => setValue('level', '', { shouldValidate: true })}
              disabled={!typesValue}
              colLabel={12}
              colWrapper={12}
              helperText={_get(dataHelpText, 'value.formHelpTextLanguagesPage.level')}
            />
          </div>
          <div className="row-block number-year">
            <TextInput
              name="experienceYears"
              control={control}
              label={t('skills:experienceYearsField')}
              required
              placeholder={t('placeholder:fieldExperienceYears')}
              suffix="年"
              colLabel={12}
              colWrapper={12}
              formLayout="vertical"
            />
          </div>
          <div className="row-block full-row">
            <TextArea
              name="detail"
              control={control}
              label={t('skills:detailField')}
              placeholder={t('placeholder:fieldDetail')}
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={false}
              colLabel={12}
              colWrapper={24}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextLanguagesPage.detail')}
            />
          </div>

          <div className="submit-btn-wrapper">
            {!_isEmpty(_get(initialForm, '_id')) && (
              <Button
                htmlType="reset"
                className="btn-cancel"
                type="primary"
                onClick={() => clearUpdateForm()}
              >
                {t('button:cancelButton')}
              </Button>
            )}
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingCreate || loadingUpdate}
              disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default KnowledgeForm;
