/* eslint-disable max-len */
const TRANSLATIONS_EN = {
  global: {
    yes: 'Yes',
    no: 'No',
    messageSuccess: 'Updated successfully.',
  },
  login: {
    login: 'Log in',
  },
  menuBar: {
    dashboard: 'Home',
    skills: 'Experience/Skills',
    workEnvironment: 'Work Environment',
    recommendation: 'Recommendation',
    introduction: 'Self-introduction',
    achievement: 'Achievements/Evaluation',
    lookBack: 'Look back',
    theme: 'Theme talk',
    users: 'Users',
    settings: 'Setting',
    register: 'Register',
    verifyEmail: 'Verify email',
    role: 'Role',
    knowledge: 'Domain Knowledge',
    technicalSkill: 'Technical Skills',
    languages: 'Languages',
    experience: 'Work Experience',
  },
  actionButton: {
    addCoach: 'Add Coach',
    uploadAndContinue: 'UPLOAD AND CONTINUE',
    addFile: 'ADD FILE',
    createAccounts: 'CREATE ACCOUNTS',
    replaceFile: 'Replace file',
    cancel: 'Cancel',
    ok: 'OK',
    createAccount: 'CREATE ACCOUNT',
    changePassword: 'Change password',
    change: 'CHANGE',
    deactivate: 'DEACTIVATE',
    active: 'ACTIVE',
    delete: 'DELETE',
    prev: 'PREV',
    next: 'NEXT',
    showToday: 'Show Today',
    showThisWeek: 'Show this week',
    showThisMonth: 'Show this month',
    showThisCycle: 'Show this cycle',
    view: 'View',
  },
  validation: {
    invalidEmail: 'This is an invalid form of e-mail address.',
    requireField: 'この項目は入力必須です。',
    requireEmail: 'メールアドレスを入力してください。',
    requireName: 'Please enter your name.',
    requirePassword: 'Please enter password.',
    requireInitial: 'Please enter your initial.',
    requireContractType: 'Please select your contract type.',
    requireAffiliateCompany: 'Please enter the name of the affiliated company.',
    requireOriginalCompany: 'Please enter your original company name.',
    requireCompanyEmail: "Please enter your company's mail-address.",
    requireStartPeriod: 'Please specify start date of employment.',
    requireEndPeriod: 'Please specify end date of employment.',
    trusteeBusinessPeriodStart: 'Please specify start date of trustee business.',
    trusteeBusinessPeriodEnd: 'Please specify end date of trustee business.',
    requireInsurance: 'Please select social insurance.',
    requireOccupation: 'Please select occupation.',
    requireNearestStation: 'Please select nearest station.',
    requireAddress: 'Please select address.',
    invalidImage: 'Images larger than 5 MB cannot be uploaded.',
    isValidPassword:
      'Passwords should be a mixture of upper and lower case alphabetical letters + numbers + symbols.',
  },
  tableForm: {
    search: 'Search',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select',
    exportResultCSV: 'Export results (CSV)',
  },
  alert: {
    clear: 'Clear',
    sessionExpired: 'Session expired',
    loginRequired: 'Your session has expired. Please log in again',
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
  button: {
    goBack: 'Go Back',
    registerButton: 'Register with Expertport',
    changeButton: 'Change',
    saveButton: 'Save',
    cancelButton: 'Cancel',
    deleteButton: 'Delete',
  },
  loading: {
    loading: 'Loading',
  },
  profile: {
    fullName: 'Full name',
    accountStatus: 'Account Status',
    lastLogin: 'Last login',
    phoneticName: 'Phonetic Name',
    email: 'Email',
    emailAddress: 'E-mail address',
    birthday: 'Birthday',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    lastName: 'Last name',
    firstName: 'First name',
    phoneticLastName: 'Phonetic last name',
    phoneticFirstName: 'Phonetic first name',
    displayName: 'Display name',
    profilePicture: 'Profile picture',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    unanswered: 'Unanswered',
    registerDate: 'Register date',
    region: 'Region',
    prefecture: 'Prefecture',
    zipCode: 'Zip code',
    businessImages: 'Business images',
    profileText: 'Profile text',
    businessName: 'Business name',
    businessNumber: 'Business number',
    addressLine1: 'Address line 1',
    municipality: 'Municipality',
    addressLine2: 'Address line 2',
    addressPrivacySettings: 'Address privacy settings',
    addressPrivacy: 'Address privacy',
    mapLocation: 'Map location',
    accountHolder: 'Account holder',
    accountNumber: 'Account number',
    accountType: 'Account type',
    bankBookImage: 'Bank book image',
    financialInstitution: 'Financial institution',
    financialInstitutionName: 'Financial institution name',
    bankCode: 'Bank code',
    branchName: 'Branch name',
    branchCode: 'Branch code',
    username: 'Username',
    password: 'Password',
    role: 'Role',
    newPassword: 'New password',
    permissionBooking: 'Bookings',
    permissionProvider: 'Provider management',
    permissionCustomer: 'Customer management',
    permissionService: 'Service management',
    description: 'Description',
  },
  detail: {},
  common: {},
  action: {},
  status: {},
  title: {
    registerFormTitle: 'Member registration',
    changeEmailFormTitle: 'Change email',
    rolesTitle: 'Possible Roles/Processes',
    qualificationTitle: 'Qualifications',
    domainKnowledgeTitle: 'Knowledgeable industry and business knowledge',
    languagesTitle: 'Languages',
    technicalTitle: 'Technical',
    technicalSkillPage: 'Technical',
    workExperienceTitle: 'Work Experience',
  },
  description: {
    deleteConfirm: 'Are you sure delete it ?',
  },
  tableColumn: {},
  columnTitle: {},

  placeholder: {
    fieldInputEmail: 'Please enter your email address',
    fieldInputConfirmEmail: 'Please re-type your email address',
    fieldInputOldPwd: 'Please enter your current passwords',
    fieldInputNewPwd: 'Please enter your new passwords',
    fieldRole: 'Roles',
    fieldProcess: 'Process',
    fieldLevel: 'Level',
    fieldExperienceYears: '3.5',
    fieldDetail: 'Please enter detail',
    fieldLicense: 'Please enter detail',
    fieldAcquisition: 'YYYY/ＭＭ',
    fieldScore: 'Please enter detail',
    fieldSelect: 'Please select',
  },
  type: {
    protect: 'Protect',
    public: 'Public',
    saving: 'Saving',
    standard: 'Standard',
  },
  menuAvatar: {
    setting: 'Setting',
    guide: 'User Guide',
    contact: 'Contact',
    logout: 'Log out',
  },
  dateViewMode: {},
  payout: {},
  service: {},
  notification: {},
  changeEmailModal: {
    requestTitle: 'Email address reset email sent',
    contentRequest:
      'A verification email has been sent to the email address you entered. \n Click the link to reset your email address.',
    successChange: 'Email address changed',
    contentSuccess: 'Please re-login with your new email address.',
  },
  changePasswordModal: {
    successChange: 'Password change successful',
    contentSuccess: 'Your password was successfully changed. Please re-login.',
  },
  skills: {
    rolesField: 'Role',
    processField: 'Process',
    experienceYearsField: 'Experience years',
    detailField: 'Detail',
    createSuccess: 'Your information has been save.',
    updateSuccess: 'Update successful',
    deleteSuccess: 'Delete successful',
    industryField: 'Indestry',
    levelField: 'Level',
    languageField: 'Language',
    skillCategoryField: 'Skill Category',
    skillName: 'Skill Name',
    skillLevel: 'Skill Level',
    skillLevelTechnical: 'Skill Level',
    projectNameField: 'Project Name',
    endUserField: 'End User',
    responsibilityCompanyField: 'Responsibility Company',
    periodStartField: 'Period Start',
    periodEndField: 'Period End',
    roleProjectField: 'Role Project',
    inChargeProcessField: 'In charge Process',
    overviewField: 'Project Overview',
  },
  certifications: {
    licenseName: 'Certification / license name',
    dateOfAcquisition: 'Date of acquisition',
    score: 'Score',
  },
  introductions: {
    memo: 'Memo',
    selfIntroductionDetail: ' Self Introduction Detail',
    linkToAperport: 'Link to Aperport',
    futureGoal: 'Future Goal',
    importantThingInWork: 'Important Thing In Work',
  },
  formHelpTextRolePage: {
    role: 'Help text',
    process: 'Help text',
    level: 'Help text',
    detail: 'Help text',
  },
  formHelpTextKnowledgePage: {
    knowledge: 'Help text',
    level: 'Help text',
    detail: 'Help text',
  },
  formHelpTextTechnicalPage: {
    level: 'Help text',
    detail: 'Help text',
  },
  formHelpTextLanguagesPage: {
    level: 'Help text',
    detail: 'Help text',
  },
  formHelpTextWorkExperiencePage: {
    endUser: 'Help text',
    responsibility: 'Help text',
    overview: 'Help text',
  },
  formHelpTextIntroductionPage: {
    memo: 'Help text',
    selfIntroductionDetail: 'Help text',
    linkToAperport: 'Help text',
    futureGoal: 'Help text',
    importantThingInWork: 'Help text',
  },
  formHelpTextWorkEnvironmentPage: {
    technicalSkillPreference: 'Help text',
    desired: 'Help text',
    choiceOfWorkingHours: 'Help text',
    choiceOfWorkingPlaces: 'Help text',
  },
  formHelpTextProfilePage: {
    companyLinked: 'Help text',
    avatarColor: 'Help text',
    emailLabel: 'Help text',
    username: 'Help text',
    initial: 'Help text',
    contractType: 'Help text',
    companyName: 'Help text',
    outsourcing: 'Help text',
    companyMailaddress: 'Help text',
  },
  connectionAP: {
    unlinkSuccess: 'The company has been terminated.',
    linkedSuccess: 'Cooperation with Aperport completed',
  },
};

export default TRANSLATIONS_EN;
