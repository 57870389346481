/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import RadioGroup from 'components/Form/RadioGroup';
import { RADIO_CHOOSE } from 'utils/constants';

// hooks
import { IWorkEnvironment } from 'models/work-environment/interface';
import { useMutate } from 'hooks';
import workEnvironmentQuery from 'models/work-environment/query';
import Helper from 'utils/helpers';

import FormSchema from './validate';
import '../workEnvironment-style.less';

type FormValues = InferType<typeof FormSchema>;
type ItemRole = {
  _id: string | any;
  name?: string;
  choose: string;
  category?: string;
};

const DesiredRoleForm: React.FC<{
  dataSource: any;
  updateData?: any;
}> = ({ dataSource, updateData }) => {
  const [initialForm, setInitialForm] = useState<Array<ItemRole>>([]);
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: dataSource,
  });
  const { t } = useTranslation();

  const { mutateAsync: update, isLoading } = useMutate<IWorkEnvironment>(
    workEnvironmentQuery.updateWorkEnvironment,
  );

  useEffect(() => {
    const allRole: any[] = [];
    dataSource.map(({ category, role }) => {
      role.map((item) => {
        allRole.push({ ...item, category });
      });
    });
    setInitialForm(allRole);
  }, [dataSource]);

  const handleFormSubmit = (values: FormValues) => {
    const submitValues: any[] = [];
    _isArray(initialForm) &&
      initialForm.map((itemHasChoose) => {
        if (!_isEmpty(_get(itemHasChoose, 'choose')))
          submitValues.push({
            _id: _get(itemHasChoose, '_id'),
            choose: _get(itemHasChoose, 'choose'),
          });
      });
    update(
      {
        rolePreference: submitValues,
      },
      {
        onSuccess: (data) => {
          Helper.toasts('', t('global:messageSuccess'), 'success');
          updateData();
        },
      },
    );
  };

  const handleChangeRadio = (event) => {
    const updatedItem = _isArray(initialForm)
      ? initialForm.map((item) =>
          item._id === event.target.name ? { ...item, choose: event.target.value } : item,
        )
      : [];
    setInitialForm(updatedItem);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} className="desired-role-wrapper">
      <Space className="header-form" style={{ width: '100%' }} size={21}>
        <div className="header-form__title">
          <Typography.Text>役割</Typography.Text>
          <Typography.Text>担当工程・タスク</Typography.Text>
        </div>
        <div className="header-form__value">
          <Typography.Text>NG</Typography.Text>
          <Typography.Text>OK</Typography.Text>
          <Typography.Text>優先</Typography.Text>
        </div>
      </Space>
      <Space direction="vertical" style={{ width: '100%' }}>
        <form
          className="desired-role-form-container details-form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className="content-details-form">
            {dataSource.map((item) => (
              <Space
                className="content-form"
                style={{ width: 'calc(100% - 50px)' }}
                size={0}
                direction="vertical"
                key={item.category}
              >
                {initialForm.map((itemRole, index) => {
                  if (itemRole.category === item.category) {
                    return (
                      <Space
                        className="item_content-form"
                        style={{ width: '100%' }}
                        size={0}
                        key={itemRole._id}
                      >
                        <Space className="content-form__title" style={{ width: '100%' }} size={0}>
                          <Typography.Text className="category-text">
                            {index === 0 && item.category}
                            {index > 0 &&
                              _get(initialForm, `${index}.category`) !==
                                _get(initialForm, `${index - 1}.category`) &&
                              item.category}
                          </Typography.Text>
                          <Typography.Text>{itemRole.name}</Typography.Text>
                        </Space>
                        <Space className="content-form__value" style={{ width: '100%' }} size={0}>
                          <RadioGroup
                            name={itemRole._id}
                            noLabel={true}
                            label={itemRole.name}
                            control={control}
                            className="not-show-value"
                            onChange={handleChangeRadio}
                            value={_get(itemRole, 'choose')}
                            options={RADIO_CHOOSE}
                          />
                        </Space>
                      </Space>
                    );
                  }
                })}
              </Space>
            ))}
          </div>

          <div className="submit-btn-wrapper">
            <Button htmlType="submit" className="second-color" type="primary" loading={isLoading}>
              {t('button:saveButton')}
            </Button>
          </div>
        </form>
      </Space>
    </Space>
  );
};

export default DesiredRoleForm;
