/* eslint-disable max-len */
import { PageContainer } from '@ant-design/pro-layout';
import { Typography, Button, Row, Col, Layout, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useMutate } from 'hooks';
import useFetchList from 'hooks/useList';
import Helper from 'utils/helpers';
import './styles.less';
import { IProject } from 'models/preference/interface';
import preferenceQuery from 'models/preference/query';
import ProjectContent from 'components/ProjectContent';
import emptyFavorite from 'assets/images/empty_favorite.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icon_delete.svg';
import DragAndDropList from 'components/DragAndDropList';

const { Title, Text } = Typography;

const arraysEqual = (a: unknown[], b: unknown[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const SelectedPreferencePage = (): JSX.Element => {
  const { t } = useTranslation();

  const { list: listOrder, isFetching: fetchingOrderData } = useFetchList<IProject>(
    preferenceQuery.orderList,
  );
  const {
    list: favoriteList,
    isFetching: fetchingFavoriteData,
    isLoading: loadingFavoriteData,
    refetch: refetchFavorite,
  } = useFetchList<IProject>(preferenceQuery.favoriteList);

  const [projectFavoriteList, setProjectFavoriteList] = useState<IProject[]>([]);

  useEffect(() => {
    if (!loadingFavoriteData && favoriteList && favoriteList.length > 0) {
      setProjectFavoriteList(favoriteList);
    }
  }, [favoriteList, loadingFavoriteData]);

  const handleDelete = (id: string) => {
    setProjectFavoriteList((prevSelectedItems) =>
      prevSelectedItems.filter((item) => item._id !== id),
    );
  };

  const handleDragEnd = (newList: IProject[]) => {
    setProjectFavoriteList(newList);
  };

  const { mutateAsync: saveFavoriteOrders, isLoading: loadingSaveFavorite } = useMutate<{
    favoriteOrders: string[];
  }>(preferenceQuery.saveFavoriteOrders);

  const handleSelect = (item: IProject) => {
    if (projectFavoriteList.length < 10) {
      setProjectFavoriteList((prevSelectedItems) => {
        if (!prevSelectedItems.find((selectedItem) => selectedItem._id === item._id)) {
          Helper.toasts('', '追加しました', 'success');
          return [...prevSelectedItems, item];
        }
        return prevSelectedItems;
      });
    } else {
      Helper.toasts('', '最大10件選択できます。', 'error');
    }
  };

  const handleSubmitFavoriteList = () => {
    saveFavoriteOrders(
      {
        favoriteOrders: projectFavoriteList.map((item) => item._id),
      },
      {
        onSuccess: () => {
          // toastr send email
          Helper.toasts('', t('global:messageSuccess'), 'success');
          refetchFavorite();
        },
      },
    );
  };

  const dataOrder = !fetchingOrderData && listOrder && listOrder.length > 0 ? listOrder : [];

  return (
    <PageContainer className="selected-preferencePage-wrapper" header={{ title: '' }}>
      <Layout.Content style={{ margin: '0' }}>
        <Row>
          <Col
            flex="330px"
            style={{ background: '#E9EBF0', height: 'calc(100vh - 48px - 75px)', maxWidth: 337 }}
          >
            <div className="side-block left">
              <div className="heading">
                <Title level={4}>引き合い商談リスト</Title>
                <Text>お気に入りを選択してください。</Text>
              </div>
              <div className="content">
                {!_isEmpty(dataOrder) &&
                  dataOrder.map((orderItem: any) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div
                      key={orderItem._id}
                      onClick={() =>
                        projectFavoriteList.some((item) => item._id === orderItem._id)
                          ? ''
                          : handleSelect(orderItem)
                      }
                      className={`
                        order-item 
                        ${
                          projectFavoriteList.some((item) => item._id === orderItem._id)
                            ? 'active'
                            : ''
                        }
                      `}
                    >
                      <ProjectContent data={orderItem} type="small" />
                    </div>
                  ))}
              </div>
            </div>
          </Col>
          <Col style={{ width: 'calc(765px - 337px)' }}>
            <div className="side-block right">
              <div className="heading">
                <Title level={4}>選好表明</Title>
                <Text>ドラックアンドドロップをして、ポジションの優先順位を変更してください。</Text>
              </div>
              <Spin spinning={fetchingFavoriteData || loadingFavoriteData}>
                <div className="content">
                  {_isEmpty(projectFavoriteList) ? (
                    <div className="empty-favorite-wrapper">
                      <img src={emptyFavorite} alt="empty" />
                      <Typography.Title level={3}>選好表明のデータはありません</Typography.Title>
                      <Typography.Text>
                        おすすめのリストからポジションを選択してください
                      </Typography.Text>
                    </div>
                  ) : (
                    <DragAndDropList<IProject>
                      className="favorite-item"
                      items={projectFavoriteList}
                      renderItem={(item) => (
                        <div key={item._id} className="favorite-item">
                          <ProjectContent data={item} type={'large'} />
                          <Button
                            className="btn-delete"
                            shape="circle"
                            icon={<Icon component={DeleteIcon} />}
                            onClick={() => handleDelete(item._id)}
                          />
                        </div>
                      )}
                      onDragEnd={handleDragEnd}
                    />
                  )}
                </div>
              </Spin>
            </div>
          </Col>
        </Row>
        <Row className="block-bottom">
          <Col span={24}>
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              disabled={loadingSaveFavorite || arraysEqual(projectFavoriteList, favoriteList || [])}
              onClick={handleSubmitFavoriteList}
            >
              {t('button:saveButton')}
            </Button>
          </Col>
        </Row>
      </Layout.Content>
    </PageContainer>
  );
};

export default SelectedPreferencePage;
