/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import TextInput from 'components/Form/TextInputNew';
import TextArea from 'components/Form/TextArea';
import MaskedInput from 'components/Form/MaskedInputNew';
import { CheckboxButtonGroup } from 'components/Form';

// hooks
import useFetchList from 'hooks/useList';
import { useMutate, useFetchUser, useFetchDetail } from 'hooks';
import resourceQuery from 'models/resource/query';
import skillsQuery from 'models/skills/query';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import Helper from 'utils/helpers';
import './style.less';
import '../skills-style.less';

import {
  IListWorkExperience,
  IUpdateWorkExperience,
  ICreateWorkExperience,
} from 'models/skills/interface';
import { ICategoryRoleItem, IInChargeProcess } from 'models/resource/interface';

import FormSchema from './validate';
import TechnologiesBox from './TechnologiesForm';

type FormValues = InferType<typeof FormSchema>;

const WorkExperienceForm: React.FC<{
  initialValue: IListWorkExperience | Record<string, unknown>;
  resetInitialValue: () => void;
  refreshDataList: () => void;
}> = ({ initialValue, resetInitialValue, refreshDataList }) => {
  // state
  const [initialForm, setInitialForm] = useState({});
  // const [resetFormTechnologies, setResetFormTechnologies] = useState(false);
  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
    watch,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialForm,
  });
  const { t } = useTranslation();

  const { data } = useFetchUser({ enabled: true });
  // get all roles
  const { list: listRole } = useFetchList<ICategoryRoleItem>(resourceQuery.roleWork);
  // get all inCharge Process
  const { list: listInchargeProcess } = useFetchList<IInChargeProcess>(
    resourceQuery.inChargeProcess,
  );

  const { mutateAsync: create } = useMutate<ICreateWorkExperience>(
    skillsQuery.createWorkExperience,
  );
  const { mutateAsync: update } = useMutate<IUpdateWorkExperience>(
    skillsQuery.updateWorkExperience,
  );

  useEffect(() => {
    if (_isEmpty(_get(initialValue, '_id', ''))) {
      setInitialForm(initialValue);
    } else {
      /** Edit Item */
      setInitialForm({
        ...initialValue,
        inChargeProcesses: Helper.arrayObjToArrayString(initialValue.inChargeProcesses),
        roles: Helper.arrayObjToArrayString(initialValue.roles),
        periodStart: moment(initialValue.periodStart).format('YYYY/MM'),
        periodEnd: initialValue.periodEnd
          ? moment(initialValue.periodEnd).format('YYYY/MM')
          : undefined,
      });
      setValue('projectName', String(_get(initialValue, 'projectName')));
      setValue('periodStart', moment(initialValue.periodStart).format('YYYY/MM'));
      setValue('inChargeProcesses', Helper.arrayObjToArrayString(initialValue.inChargeProcesses));
      setValue('roles', Helper.arrayObjToArrayString(initialValue.roles));
    }
    clearErrors();
    // setResetFormTechnologies(true);
  }, [initialValue]);

  useEffect(() => {
    reset(initialForm);
  }, [initialForm]);

  const clearUpdateForm = () => {
    // setInitialForm({});
    resetForm();
    resetInitialValue();
  };

  const resetForm = () => {
    setValue('projectName', '');
    setValue('endUser', '');
    setValue('responsibilityCompany', '');
    setValue('periodStart', '');
    setValue('periodEnd', '');
    setValue('roles', []);
    setValue('inChargeProcesses', []);
    setValue('technologies', []);
    setValue('projectOverview', '');
    reset();
  };

  const handleFormSubmit = (values: FormValues) => {
    if (_isEmpty(_get(initialForm, '_id'))) {
      create(
        {
          ...values,
          user: data?._id || '',
          periodStart: moment(values.periodStart, 'YYYY/MM'),
          periodEnd: values.periodEnd ? moment(values.periodEnd, 'YYYY/MM') : undefined,
          technologies: values.technologies
            ? Helper.convertToTechnologiesArray(values.technologies)
            : [],
        },
        {
          onSuccess: () => {
            Helper.toasts('', t('global:messageSuccess'), 'success');
            resetForm();
            refreshDataList();
          },
        },
      );
    } else {
      update(
        {
          ...values,
          workExperience: _get(initialForm, '_id', '') as string,
          periodStart: moment(values.periodStart, 'YYYY/MM'),
          periodEnd: values.periodEnd ? moment(values.periodEnd, 'YYYY/MM') : undefined,
          technologies: values.technologies
            ? Helper.convertToTechnologiesArray(values.technologies)
            : [],
        },
        {
          onSuccess: () => {
            Helper.toasts('', t('skills:updateSuccess'), 'success');
            clearUpdateForm();
            refreshDataList();
          },
        },
      );
    }
  };

  return (
    <form
      className="skills-form-container work-experience-form-container"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {/* <Spin spinning={fetchingCategoryData || fetchingLevelData}> */}
      <div className="form-block">
        <div className="row-block">
          <TextInput
            name="projectName"
            label={t('skills:projectNameField')}
            control={control}
            formLayout="vertical"
            required
            placeholder={t('placeholder:fieldDetail')}
            colLabel={12}
            colWrapper={24}
            maxLength={100}
          />
        </div>
        <div className="row-block">
          <TextInput
            name="endUser"
            label={t('skills:endUserField')}
            control={control}
            formLayout="vertical"
            placeholder={t('placeholder:fieldDetail')}
            colLabel={12}
            colWrapper={24}
            maxLength={100}
            helperText={_get(dataHelpText, 'value.formHelpTextWorkExperiencePage.endUser')}
          />
        </div>
        <div className="row-block">
          <TextInput
            name="responsibilityCompany"
            label={t('skills:responsibilityCompanyField')}
            control={control}
            formLayout="vertical"
            placeholder={t('placeholder:fieldDetail')}
            colLabel={12}
            colWrapper={24}
            maxLength={100}
            helperText={_get(dataHelpText, 'value.formHelpTextWorkExperiencePage.responsibility')}
          />
        </div>
        <div className="row-block">
          <MaskedInput
            name="periodStart"
            label={t('skills:periodStartField')}
            control={control}
            mask="9999/99"
            required
            placeholder="YYYY/MM"
            formLayout="vertical"
            colLabel={12}
            colWrapper={12}
          />
        </div>
        <div className="row-block">
          <MaskedInput
            name="periodEnd"
            label={t('skills:periodEndField')}
            mask="9999/99"
            control={control}
            placeholder="YYYY/MM"
            formLayout="vertical"
            colLabel={12}
            colWrapper={12}
          />
        </div>
        <div className="row-block">
          <CheckboxButtonGroup
            name="roles"
            label={t('skills:roleProjectField')}
            control={control}
            required
            colLabel={12}
            colWrapper={24}
            formLayout="vertical"
            options={listRole}
          />
        </div>
        <div className="row-block">
          <CheckboxButtonGroup
            name="inChargeProcesses"
            label={t('skills:inChargeProcessField')}
            control={control}
            required
            colLabel={12}
            colWrapper={24}
            formLayout="vertical"
            options={listInchargeProcess}
            isCustomDisplayCheckbox={true}
          />
        </div>
        <div className="row-block">
          <Typography.Title level={5}>使用技術</Typography.Title>
        </div>
        <div className="row-block">
          <TechnologiesBox
            initialData={_get(initialValue, 'technologies', []) || []}
            data={watch('technologies') || []}
            handleSetTechnologies={(values) => {
              setValue('technologies', values);
            }}
          />
        </div>
        <div className="row-block full-row">
          <TextArea
            name="projectOverview"
            control={control}
            label={t('skills:overviewField')}
            placeholder={t('placeholder:fieldDetail')}
            maxLength={2000}
            rows={8}
            showCount={false}
            colLabel={12}
            colWrapper={24}
            formLayout="vertical"
            helperText={_get(dataHelpText, 'value.formHelpTextWorkExperiencePage.overview')}
          />
        </div>

        <div className="submit-btn-wrapper">
          {!_isEmpty(_get(initialForm, '_id')) && (
            <Button
              htmlType="reset"
              className="btn-cancel"
              type="primary"
              onClick={() => clearUpdateForm()}
            >
              {t('button:cancelButton')}
            </Button>
          )}
          <Button
            htmlType="submit"
            className="second-color"
            type="primary"
            // loading={loadingCreate || loadingUpdate}
            disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
          >
            {t('button:saveButton')}
          </Button>
        </div>
      </div>
      {/* </Spin> */}
    </form>
  );
};

export default WorkExperienceForm;
