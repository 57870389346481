import { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import TextArea from 'components/Form/TextArea';

// hooks
import { useMutate } from 'hooks';
import workEnvironmentQuery from 'models/work-environment/query';
import { IWorkEnvironment } from 'models/work-environment/interface';
import Helper from 'utils/helpers';

import FormSchema from './validate';

import '../workEnvironment-style.less';

type FormValues = InferType<typeof FormSchema>;

const WorkingPlaceForm: React.FC<{
  initialValue?: Record<string, undefined>;
  updateData?: any;
}> = ({ initialValue, updateData }) => {
  // state
  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();
  const { mutateAsync: update, isLoading } = useMutate<IWorkEnvironment>(
    workEnvironmentQuery.updateWorkEnvironment,
  );

  const handleFormSubmit = (values: FormValues) => {
    update(
      {
        ...values,
        choiceOfWorkingPlace: values.choiceOfWorkingPlace,
      },
      {
        onSuccess: (data) => {
          Helper.toasts('', t('global:messageSuccess'), 'success');
          updateData();
        },
      },
    );
  };

  useEffect(() => {
    reset({
      choiceOfWorkingPlace: _get(initialValue, 'choiceOfWorkingPlace', ''),
    });
  }, [initialValue]);

  return (
    <form className="working-place-form-container" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-block">
        <Spin spinning={isLoading}>
          <div className="row-block full-row">
            <TextArea
              name="choiceOfWorkingPlace"
              control={control}
              placeholder={t('placeholder:fieldDetail')}
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={true}
              colLabel={2}
              colWrapper={24}
              formLayout="vertical"
            />
          </div>
        </Spin>

        <div className="submit-btn-wrapper">
          <Button htmlType="submit" className="second-color" type="primary" loading={isLoading}>
            {t('button:saveButton')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default WorkingPlaceForm;
