import React from 'react';
import { Typography } from 'antd';

type LevelIndicatorProps = {
  text: string;
  color?: string;
};

const LevelIndicator: React.FC<LevelIndicatorProps> = ({ text, color = '#3D405B' }) => {
  const japaneseText = text.match(/[^\d]+/)?.[0] || '';
  const level = parseInt(text.match(/\d+/)?.[0] || '0', 10);

  const bars = [1, 2, 3].map((index) => {
    let height;
    if (index === 1) height = 6;
    else if (index === 2) height = 8;
    else if (index === 3) height = 10;

    return (
      <div
        key={index}
        style={{
          width: 4,
          height: height,
          backgroundColor: index <= level ? color : 'rgba(61, 64, 91, 0.32)',
          borderRadius: '1px',
          marginRight: 1,
        }}
      />
    );
  });

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ display: 'flex', alignItems: 'flex-end', marginRight: 4, marginBottom: 4 }}>
        {bars}
      </div>

      <Typography.Text
        style={{ fontSize: 10, marginRight: 2, marginBottom: 2, lineHeight: '14px' }}
      >
        {japaneseText}
      </Typography.Text>

      <Typography.Text strong style={{ fontSize: 16, lineHeight: '23px' }}>
        {level}
      </Typography.Text>
    </div>
  );
};

export default LevelIndicator;
